<template>
    <div>
        <lines-desktop :long-term-lines="longTermLines" :lines="lines"/>
        <lines-mobile :long-term-lines="longTermLines" :lines="lines"/>
        <div class="row mob-cart">
            <delivery-addresses class="col-6 col-md-8"/>
            <totals class="col-6 col-md-4 text-right"/>
        </div>
    </div>
</template>

<script>
import CartLinesDesktop from "./CartLinesDesktop";
import CartLinesMobile from "./CartLinesMobile";
import CartDeliveryAddresses from "./CartDeliveryAddresses";
import CartTotals from "./CartTotals";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "DesktopCart",
  components: {
    'lines-desktop': CartLinesDesktop,
    'lines-mobile': CartLinesMobile,
    'delivery-addresses': CartDeliveryAddresses,
    'totals': CartTotals
  },
  props: {
    longTermLines: {
      type: Array,
      default: () => []
    },
    lines: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div @click="quantity == 1 ? deleteLine({productId, sizeId}) : removeProductUpdateLine({productId, sizeId})" class="minus">
      <img src="@/assets/images/minus.svg">
    </div>
    <div class="number">
      <p class="ten">
        <span :class="{'not-available-item': itemNotAvailable}">{{quantity}}</span>
      </p>
    </div>
    <div @click="addProductUpdateLine({productId, sizeId})" class="plus">
      <img src="@/assets/images/plus.svg">
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {showConfirm} from "@/lib/swal2";

export default {
  name: "CartLineProductQuantityMobile",
  props: {
    productId: {
      type: String
    },
    sizeId: {
      type: Number
    },
    quantity: {
      type: Number
    },
    itemNotAvailable: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {},
  methods: {
    ...mapActions('cart/dealer', ['addProductUpdateLine', 'removeProductUpdateLine', 'deleteLine']),
  }
}
</script>

<style scoped>

.minus, .plus {
  width: 25px;
  height: 100%;
  cursor: pointer;
  padding: 4px 0px;
}

.number {
  margin: 0 5px;
}

.ten {
  margin-top: 6px;
}

.not-available-item{
  color: red;
}

@media (max-width: 767px) {
  .minus, .number, .plus {
    float: left;
    display: inline-block;
    padding: 0;
  }
}
</style>
<template>
    <div>
        <h3 class="del_address">Адрес доставки:</h3>
        <label class="control control--radio ng-star-inserted" v-for="(address, key) in deliveryAddresses" :key="key">
            {{ address.address }}
            <input type="radio" name="radio" :checked="address.id === selectedAddress" :value="address.id"
                    @change="selectAddress(address.id)"/>
            <div class="control__indicator"></div>
        </label>
        <p class="isInvalid mt-4">Данные указаны неверно?
            <router-link :to="{name: 'Contacts'}"><u>Свяжитесь с нами!</u></router-link>
        </p>
        <div v-if="isSplitOrder" class="del__long-term-production">
          <h3 class="long-term__title pt-0">В вашем заказе есть товары длительного производства. Выберите вариант доставки:</h3>
          <label class="control control--radio ng-star-inserted">
            Получить все товары одним грузом
            <div class="long-term__message">
                <DangerIcon />
                Выбирая данный вариант доставки, Вы соглашаетесь с длительным ожиданием всего заказа (от 40 рабочих дней).
            </div>
            <input
                type="radio"
                name="splitOrder"
                :checked="splitOrder === false"
                :value="false"
                @change="changeSplitOrder(false)"
            />
            <div class="control__indicator"></div>
          </label>
          <label class="control control--radio ng-star-inserted">
              Разделить заказ на 2 части
              <div class="long-term__message">
                  <DangerIcon />
                  Будет сформировано 2 заказа и выставлено 2 счета для оплаты. Доставка заказов будет осуществляться по мере производства.
              </div>
              <input
                  type="radio"
                  name="splitOrder"
                  :checked="splitOrder === true"
                  :value="true"
                  @change="changeSplitOrder(true)"
              />
              <div class="control__indicator"></div>
          </label>
        </div>
        <div class="del__email">
            <label class="del__label">E-mail получателя*</label>
            <ValidationProvider
                rules="email"
                v-slot="{ errors }"
                ref="emailField"
            >
                <input
                    type="text"
                    class="del__email-input "
                    :value="deliveryEmail"
                    @input="setDeliveryEmail($event.target.value)"
                    name="deliveryEmail"
                    placeholder="Введите e-mail"
                />
                <span
                    class="error-message"
                    v-if="errors[0]"
                >
                    <br />
                    **{{ errors[0] }}
                </span>
            </ValidationProvider>
        </div>
        <div class="del__email">
            <label class="del__label">Телефон получателя*</label>
            <ValidationProvider
                rules="min: 11"
                v-slot="{ errors }"
            >
                <input
                    type="text"
                    class="del__email-input "
                    :value="deliveryPhone"
                    @input="setDeliveryPhone($event.target.value)"
                    name="deliveryPhone"
                    placeholder="Введите телефон"
                    @keypress="typ($event)"
                />
                <span
                    class="error-message"
                    v-if="errors[0]"
                >
                    <br />
                    **{{ errors[0] }}
                </span>
            </ValidationProvider>
        </div>
        <div>
            !По указанным контактам агентство связывается для подтверждения заказа и отправляет документы для оплаты счета за сувенирную продукцию
        </div>
        <div v-if="!isEnoughBalance" class="mt-5 d-none d-md-block">
            <span class="icon-error float-left mr-3"></span>
            <p class="notify-note">
              На Вашем счете недостаточно средств, сократите, пожалуйста,
              заказ, удалив или изменив количество позиций в вашем заказе.
            </p>
        </div>
        <div v-if="closePurchaseByDate() || getConfigValue('isOrderCreatingDisabled')" class="mt-5 d-none d-md-block">
            <span class="icon-error float-left mr-3"></span>
            <p class="notify-note">
              Прием заказов прекращен до {{ closeOrdersDealerByDate }}. С информацией можно ознакомиться в разделе
              <router-link :to="{name: 'HowToOrder'}">«Как заказать?»</router-link>
            </p>
        </div>
        <div v-if="!areSameAgenciesProducts" :class="{'mt-5': isEnoughBalance}" class="d-flex flex-wrap align-items-center">
            <span class="icon-error mr-3"></span>
            <div>
                <p class="notify-note">Доставка осуществляется двумя разными партнерами в зависимости от категории товара.</p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import { ValidationProvider } from 'vee-validate';
import DangerIcon from "@/components/visit/DangerIcon.vue";
export default {
    name: "CartDeliveryAddresses",
    components: {
      DangerIcon,
        ValidationProvider,
    },
    computed: {
        ...mapGetters('cart/dealer', [
          'cartLines',
          'longTermLines',
          'deliveryAddresses',
          'selectedAddress',
          'isEnoughBalance',
          'areSameAgenciesProducts',
          'getTotal',
          'deliveryEmail',
          'deliveryPhone',
          'splitOrder',
        ]),
        ...mapGetters('common', ['getConfigValue']),
        ...mapGetters('builtInParameter', ['closeOrdersDealerByDate']),
        isSplitOrder() {
            return this.cartLines.length && this.longTermLines.length;
        }
    },
    methods: {
        ...mapMutations('cart/dealer', ['SET_SELECTED_ADDRESS', 'SET_DELIVERY_EMAIL', 'SET_DELIVERY_PHONE', 'SET_SPLIT_ORDER']),
        changeSplitOrder(isSplitOrder) {
            this.SET_SPLIT_ORDER(isSplitOrder);
        },
        selectAddress(address) {
            this.SET_SELECTED_ADDRESS(address);
        },
        closePurchaseByDate() {
            return this.closeOrdersDealerByDate !== '';
        },
        isFieldEmpty(fieldValue) {
            return fieldValue.length === 0;
        },
        typ: function(event){
            if(event.code.includes('Key')){
                event.preventDefault()
            }
        },
        setDeliveryEmail(email) {
            this.SET_DELIVERY_EMAIL(email)
        },
        setDeliveryPhone(phone) {
            this.SET_DELIVERY_PHONE(phone)
        }
    },
}
</script>

<style scoped>
.long-term__message {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #D2233C;
  & svg {
    min-width: 20px;
  }
}
h3 {
    padding-top: 30px;
}

.table thead th {
    border: none !important;
}

th {
    font-weight: 700;
    font-size: 12px;
}

.table td, .table th {
    border: none;
    border-bottom: 1px solid rgba(179, 179, 179, 0.35);
}

.table td {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
}

td {
    font-weight: 600;
    vertical-align: middle;
    font-size: 16px;
}

.del_address, .long-term__title{
    font-weight: bold;
    font-size: 18px;
}


.notify-note {
    font-size: 14px;
    line-height: normal;
    padding: 5px 0 5px 0;
    margin-bottom: 0;
}

.icon-error {
    display: inline-block;
    width: 26px;
    height: 26px;
    background-image: url("../../assets/images/alarm.svg");
    background-repeat: no-repeat;
}

.del__email {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 32px 0 24px 0;
}

.del__email-input {
    padding: 12px;
    width: 100%;
}

.del__label {
    font-size: 16px;
}

.error-message {
    color: #e5173f;
}

@media (max-width: 767px) {
    .icon-error {
        margin-bottom: 20px;
    }

    .isInvalid {
        font-size: 12px;
        text-align: left;
        color: #4a4a4a;
    }
}
</style>

<template>
  <table class="table prod-table">
    <thead>
    <tr>
      <th></th>
      <th>Описание</th>
      <th>Цена</th>
      <th>Количество</th>
      <th>Сумма</th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="lines.length && longTermLines.length">
      <td colspan="6">
        <h2 class="table-subtitle">Товары со стандартным сроком производства</h2>
      </td>
    </tr>
    <cart-line v-for="(line, key) in lines" :line="line" :key="key" />
    <tr v-if="lines.length && longTermLines.length">
      <td colspan="6">
        <h2 class="table-subtitle">Товары длительного производства</h2>
      </td>
    </tr>
    <cart-line v-for="(line, key) in longTermLines" :line="line" :key="key" />
    </tbody>
  </table>
</template>

<script>
import CartLine from "./CartLineDesktop";

export default {
  name: "CartLines",
  components: {CartLine},
  props: {
    longTermLines: {
      type: Array,
      default: () => []
    },
    lines: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.table thead th {
  border: none !important;
}

.table-subtitle {
  font-size: 1.1rem;
  font-weight: bold;
}

th {
  font-weight: 700;
  font-size: 12px;
}

.table td, .table th {
  border: none;
  border-bottom: 1px solid rgba(179, 179, 179, 0.35);
}

.table td {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
}

td {
  font-weight: 600;
  vertical-align: middle;
  font-size: 16px;
}

@media (max-width: 767px) {
  .table {
    display: none;
  }
}

</style>

<template>
  <div>
    <div class="container full-section mb-5" v-if="((cartLines && cartLines.length > 0) || (longTermLines && longTermLines.length)) && isCartLoaded">
      <h1 class="mt-3">Корзина</h1>
      <cart-content :long-term-lines="longTermLines" :lines="cartLines"/>
    </div>
    <empty-cart v-if="isCartLoaded && (!cartLines || cartLines.length === 0) && (!longTermLines || longTermLines.length === 0)" />
  </div>
</template>

<script>
import CartContent from "./CartContent";
import EmptyCart from "./EmptyCart"

import {mapGetters} from "vuex";

export default {
  name: "Cart",
  components: {
    CartContent,
    EmptyCart
  },
  props: {
    lines: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('cart/dealer', ['isCartLoaded', 'cartLines', 'longTermLines'])
  }
}
</script>

<style scoped>
</style>

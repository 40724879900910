<template>
    <div>
        <div class="notification" v-if="hasContract && isCartLoaded">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <!--<span class="icon-error float-left mr-3"></span>-->
                        <p class="notify-note text-white">
                            ВНИМАНИЕ! В вашем профиле отсутствует информация о договоре с агентством по выпуску
                            сувенирной
                            продукции.<br>
                            После оформления заказа с вами свяжется представитель Агентства и согласует условия оплаты и
                            доставки.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <cart/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Cart from "@/components/dealer/Cart";

export default {
    name: "DealerCart",
    components: {
        Cart
    },
    mounted() {
        this.getCartInfo();
        this.getDeliveryAddresses();
    },
    methods: {
        ...mapActions('cart/dealer', ['getCartInfo', 'getDeliveryAddresses'])
    },
    computed: {
        ...mapGetters('cart/dealer', ['isCartLoaded', 'hasContract'])
    },
    metaInfo() {
        return {
            title: `Корзина - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>
h3 {
    padding-top: 30px;
}

.notification {
    background-color: #4a4a4a;
}

.notify-note {
    font-size: 12px;
    line-height: normal;
    padding: 5px 0 5px 0;
    margin-bottom: 0;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .container:not(.notification) {
        max-width: 1000px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .container:not(.notification) {
        max-width: 950px;
    }
}

</style>

<template>
  <div class="d-block d-md-none mb-4">
    <h2 v-if="lines.length && longTermLines.length" class="table-subtitle">Товары со стандартным сроком производства</h2>
    <div class="infoWrap" v-for="(line, key) in lines" :key="key">
      <cart-line :line="line"/>
    </div>
    <h2 v-if="lines.length && longTermLines.length" class="table-subtitle mt-2">Товары длительного производства</h2>
    <div class="infoWrap" v-for="(line, key) in longTermLines" :key="key">
      <cart-line :line="line"/>
    </div>
  </div>
</template>

<script>
import CartLineMobile from "./CartLineMobile";

export default {
  name: "CartLinesMobile",
  components: {
    'cart-line': CartLineMobile
  },
  props: {
    longTermLines: {
      type: Array,
      default: () => []
    },
    lines: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.table-subtitle {
  font-size: 1.1rem;
  font-weight: bold;
}
@media (max-width: 767px) {
  .infoWrap {
    display: table;
    width: 100%;
  }

  .infoWrap {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(179, 179, 179, 0.35);
  }
}
</style>
